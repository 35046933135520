.tag {
    margin-right:4px;
}
.card-toolbar {
    top:30px;
}
.card-toolbar-right{
    position: absolute;
    float:right;
}
.heading{
    font-size: 28px;
}
.location-decoration{
    text-decoration: none;
    color: #000;
    cursor: pointer;
}
.clipboard-text {
    opacity: 0;
    position: absolute;
}

.detail-container {
    padding-top:80px;
}
