.list-heading{
    color:var(--ion-color-dark-shade);
}
.list-heading-wrapper {
    background-color:white;
}
.list-link{
    color:var(--ion-color-light-contrast);
    text-decoration: none;
    font-weight: bold;
}

.gs {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

@media(min-width:388px) {
    .gs {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 38%;
    }
}

.divder{
    height:5px;
    background-color:#f3f3f3;
}