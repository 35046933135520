

.login-header {
    padding-top:20px;
    padding-bottom:20px;
    margin-bottom:-10px;
    color:var(--ion-color-primary-contrast);
}

.login-button{
    width:65%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}



.login-text{
    width:65%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    text-align: left;
}
