.landing-header {
    padding-top:20px;
    padding-bottom:20px;
    margin-bottom:-10px;
    color:var(--ion-color-primary-contrast);
}
.landing-button{
    width:65%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.landing-subheader{
    color:#333;
}