.fab-button {
    --padding-end:7px;
    --padding-top:0px;
}

.featured-card{
    background-color:var(--ion-color-primary);
    color:var(--ion-color-light);
    cursor: pointer;
}
.featured-card-alt {
    background-color: var(--ion-color-secondary);
    color:var(--ion-color-light)!important;
}

.cause-desc-col {
    width:50%;
}

.causes-list {
    padding-left:5px;
    padding-bottom:20px;
    background-color:var(--ion-color-primary-tint-bg);
}
.causes-card{
    width:44%;
    height:210px;
    float:left;
    margin-left:10px;
    margin-right:10px;
    margin-bottom:0px;
}

@media  all and (min-device-width: 828px) {
    .causes-card{
        height:240px;
    }
}
.causes-header{
    padding-top:4px;
    padding-bottom:0px;
    padding-left:10px;
    border-top:5px solid var(--ion-color-secondary);
}
.causes-title {
    color:black;
    margin-top:4px;
    margin-bottom:2px;
    font-size:0.8em;
}
/* 5 chars */
.causes-title-1 {
    font-size:1.2em;
}
.causes-title-2 {
    font-size:1.0em;
}
.causes-title-3 {
    font-size:0.95em;
}
.causes-title-4 {
    font-size:0.90em;
}
.causes-body {
    padding:0px;
}
.causes-body * {
    font-size:14px;
}
.causes-item {
    padding:0px;
    --padding-start:0px;
    --padding-end:0px;
}
.causes-datetime, .causes-location{
    font-size:12px!important;
    color:var(--ion-color-dark);
    padding-left:10px;
}
.causes-title{
    font-size:16px!important;
    color:var(--ion-color-dark);
    padding-left:10px;
}
.causes-date{
    margin-top:0px;
    font-weight:500;
    font-size:13px;
    padding-left:5px;
    padding-top:0px;
    padding-bottom:0px;
}
.causes-time {
    font-size:10px;
    color:gray;
    padding-left:5px;
    padding-top:0px;
    padding-bottom:0px;
}
.causes-search-header {
    --background:var(--ion-color-primary-tint-bg);
    --color:white;
    margin:0px;
    padding-top:0px;
    height:30px;
    min-height: 30px;
    font-size:16px;
}
.causes-search-item{
    --padding-start:0px; 
    margin:20px;
    background-color: var(--ion-color-primary-tint-bg);
}
.causes-search-favorite {
    padding:0px;
    margin:0px;
}

.causes-search-datetime{
    color:var(--ion-color-medium);
}
.causes-search-details{
    color:var(--ion-color-medium);
}
.cause-begin-button{
    margin-left:-15px !important;
}
.cause-second-button{
    margin-left:45px !important;
}
.cause-carousel {
    position:relative;
    height:340px;
    width:100%;
    overflow:hidden;
    overflow-x:scroll;
    -webkit-overflow-scrolling: touch;
}
.cause-carousel-wrapper{
    position:relative;
    max-width:1000000px;
    min-width:auto;
    height:340px;
    white-space: nowrap;
}
.cause-carousel-item{
    position:relative;
    width:280px;
    height:280px;
    display:inline-block;
    margin-right:0;
}
.cause-bottom-content{
    position:absolute;
    bottom:10px;
    left:10px;
    margin:0;
    padding:5px;
    padding-left:8px;
    padding-right:8px;
    color:white !important;
    text-shadow:1px 1px 3px black;
    background-color:#383a3ed4;
    border-radius:5px;
    white-space: pre-wrap;
    max-width: calc( 100% - 18px );
}
.cause-bottom-content .causes-title,.cause-bottom-content .causes-item {
    color:white !important;
    text-shadow:1px 1px 3px black;
}
.cause-refresher {
    z-index: 1000!important;
}