ion-content {
    --background: url('/assets/volunteer2.jpg') no-repeat center cover 100% 100%;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
    background-color: var(--ion-color-primary-tint-bg);
}
.background-tint {
    background-color:var( --ion-color-primary-tint-bg);
    background-blend-mode:soft-light;
  }
.page {
    background-image:url("/assets/volunteer2.jpg");
}
.seal-background{
    opacity:.2;
    position:absolute;
    left:50%;
    margin-left:-240px;
    z-index:-1;
}

.row {
    margin-left: -16px;
    margin-right: -16px;
}

.ppr-sdw {
    box-shadow:1px 1px 3px gray;
}
.top {
    z-index:2;
}
.w-100 {
    width:100%;
}
.text-link {
    text-decoration: underline;
}
.clickable {
    cursor: pointer;
}

.header-ios ion-toolbar:last-of-type {
    --border-width: 0 0 0px;
    --padding-top:0px;
}

.slim-button {
    height:1.4em;
    margin-left:-15px;
    margin-top:-10px;
    margin-right:-10px;
}
.slim-button .button-native {
    padding-left:.5em !important;
    padding-right:.5em !important;
}

/* Ionic Override */
ion-card-title {
    color:var(--ion-color-primary-tint-bg);
}
ion-card-content {
    color:var(--ion-color-dark);
}
ion-list {
    background-color: var(--ion-color-primary-tint-bg)!important;
}
.heading {
    color:var(--ion-color-primary-tint-bg);
}
.requirement  {
    margin-right:.25rem;
}

@keyframes flickerAnimation {
    10%   { opacity:0.8; }
    50%  { opacity:0.2 }
    80% { opacity:0.8; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:0.8; }
    50%  { opacity:0.2 }
    100% { opacity:0.8; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:0.8; }
    50%  { opacity:0.2 }
    100% { opacity:0.8; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:0.8; }
    50%  { opacity:0.2 }
    100% { opacity:0.8; }
  }

.network-indicator{
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
     animation: flickerAnimation 1s infinite;
}
.header-md::after {
    background-image: none !important;
}
.no-box-shadow {
    --box-shadow: none !important;
}
.gold-separator {
    height:4px;
    background-color:var(--ion-color-secondary);
}

.gray-separator {
    height:1px;
    background-color:var(--ion-color-medium);
}


.white-separator-md {
    height:1px;
    background-color:var(--ion-color-light);
    width:65%;
    border-width: 1px;
}

.narrow-divider{
    min-height:10px;
}

.landing-title {
    color:var(--ion-color-light) !important;
}
.landing-body {
    color:var(--ion-color-light) !important;
}

.progress-placeholder {
    min-height:3px;
}

.top-buffer {
    margin-top:45px;
}
@media  all and (min-device-width: 828px) {
    .progress-placeholder {
        display:none;
    }
    .top-buffer {
        margin-top:0px!important;
    }
}

.picker-col {
    padding:0 !important;
}
.picker-opt {
    font-size:16px !important;
}
.underline{
    text-decoration: underline;
}

.new-logo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 45%;
}
