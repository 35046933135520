.media-thumbnail-text {
    position:absolute;
    left:0;
    bottom:0;
    background-color:white;
    overflow:hidden;
    width:100%;
    margin:0;
    padding:.25rem;
    text-align:center;
    opacity:.8;
}
.media-hide { 
    display:none;
}
.media-hidden-file {
    opacity:0;
    z-index:1;
    width:100%;
    height:100%;
    position:absolute;
}
.media-scroll-y {
    max-height:300px;
    overflow-y:auto;
}
.media-new {
    border:1px solid #c8c7cc;
}
.media-file-option {
    border:1px solid lightgray;
}
.media-v-align-top {
    vertical-align: top;
}
.media-edit-fab-button{
    margin-right:15px;
    margin-bottom:-5px;
}
.media-fab-button{
    margin-top:-15px;
}
.media-w-100 {
    width:100%;
}
