.count-num {
    font-size: 45px;
    color:var(--ion-color-primary);
    font-weight:500;
}
.sloagan-row{
    background-color: var(--ion-color-secondary);
    font-weight:500;
    font-size:18px;
    padding-top:5px;
    padding-bottom:5px;
}

.landing-header {
    padding-top:20px;
    padding-bottom:0px;
    margin-bottom:-10px;
}

.right-divider {
    border-right:1px solid #eee;
}